import smoothscroll from 'smoothscroll-polyfill';

// Initialize the window.scrollTo() polyfill for smooth scrolling
smoothscroll.polyfill();

// It's necessary to allow http:// (without s) in the regex, otherwise the
// script won't work on localhost, which is not handy for developers.
const domainRegex = new RegExp('https?://([a-z]([a-z0-9]+)?\.)?' + __CHIPTA_DOMAIN_NAME__, 'i');

window.addEventListener('message', event => {

  if (!domainRegex.test(event.origin)) {
    return;
  }

  var containerEl = document.getElementById('__ChiptaTicketshopIframe__');
  var iframeEl = containerEl.getElementsByTagName('iframe')[0];
  var minHeight = parseInt(iframeEl.style.minHeight);
  var data = event.data;

  function setIframeHeight(height) {

    containerEl.style.height = height + 'px';

    if (containerEl.clientHeight < minHeight) {
      iframeEl.style.minHeight = 'auto';
    } else {
      iframeEl.style.minHeight = minHeight;
    }

    iframeEl.height = containerEl.clientHeight + 'px';

  }

  switch (data.task) {

    case 'scrollTo':
      window.scrollTo({
        top: (
          window.scrollY // currently scrolled offset
          + iframeEl.getBoundingClientRect().top // element offset from viewport
          + data.top // the position inside the ticketshop that should be scrolled to
        ),
        behavior: data.behavior
      });
      break;

    case 'scrollHeight':
      if (containerEl.dataset.autoHeight) {
        if (data.height > minHeight) {
          setIframeHeight(data.height);
        } else {
          setIframeHeight(minHeight);
        }
      }
      break;

    case 'openUrl':
      window.top.location.href = data.url;
      break;

    case 'iframeThirdPartyCookiesFix':
      window.top.location.href = (
        data.baseUrl + '/tickets/iframe-third-party-cookies-fix/?return-url='
        + encodeURI(window.top.location.href)
      );
      break;

    case 'getPositionAndDimentionData':
      event.source.postMessage(
        {
          task: 'positionAndDimentionDataResponse',
          iframeBoundingClientRect: iframeEl.getBoundingClientRect(),
          window: {
            'innerHeight': window.innerHeight,
            'innerWidth': window.innerWidth
          }
        },
        event.origin
      );
      break;

  }

});
